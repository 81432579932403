import React from 'react';

import { subject } from '@casl/ability';
import { Form } from 'react-final-form';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { GoogleMapsAddress } from '@travauxlib/shared/src/types/api/common/GoogleMapsAddress';
import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';
import { required, validateEmail } from '@travauxlib/shared/src/utils/form';

import { useMultiUser } from 'features/App/hooks/useMultiUser';
import { useClubProPaywall } from 'hooks/useClubProPaywall';
import { useIsAllowed } from 'hooks/usePermissions';
import { Address } from 'types';

import { AddressField } from './AddressField';

import { useHandleSubmitDeal } from '../../hooks/useHandleClientDetails';

type DefaultValues = Pick<
  DealView,
  'customerName' | 'customerPhoneNumber' | 'customerEmail' | 'proClientUuid'
> & {
  isHemea?: boolean;
  proUserUuid?: string;
};

export type Props = {
  deal?: Partial<DealView>;
  initialValues?: DefaultValues;
  handleClose?: () => void;
};

type FormValues = {
  chantierAddress?: Address;
  chantierFormattedAddress?: string;
  chantierAddressComplement?: string;
  disabledChantierGoogleMaps?: boolean;
  chantierGoogleMapsAddress?: GoogleMapsAddress;
  sameBillingAndChantierAddress?: boolean;
  proUserUuid?: string;
};

export const DealClientDetails: React.FC<Props> = ({ deal, handleClose, initialValues }) => {
  const handleSubmitDeal = useHandleSubmitDeal();
  const { profile, proUsers, hasMultiUsersAccess } = useMultiUser();
  const defaultProUser = deal?.proUserUuid || profile.uuid;

  const isAllowed = useIsAllowed(subject('Deal', { isHemea: deal?.isHemea }), 'manage');

  useClubProPaywall({ isExistingDeal: !!deal, isAllowed });

  if (!isAllowed) {
    return null;
  }

  return (
    <Form<DealView & FormValues>
      onSubmit={async values => {
        await handleSubmitDeal({
          ...values,
          chantierGoogleMapsAddress: values.disabledChantierGoogleMaps
            ? undefined
            : values.chantierGoogleMapsAddress,
        });

        handleClose?.();
      }}
      initialValues={{
        ...(initialValues || deal),
        proUserUuid: defaultProUser,
        disabledChantierGoogleMaps: deal && !deal.chantierGoogleMapsAddress,
      }}
    >
      {({ handleSubmit }) => (
        <ModalContent
          validateAction={{
            label: deal?.uuid ? 'Valider' : 'Créer un nouveau chantier',
            type: 'submit',
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: handleClose,
          }}
          data-testid="deal-project-form"
          handleSubmit={handleSubmit}
        >
          <div className="!mb-xmd">
            {hasMultiUsersAccess && (
              <DropdownField<string>
                label="Responsable"
                id="deal-title"
                data-testid="deal-title"
                name="proUserUuid"
                options={proUsers.map(user => ({
                  label: user.fullName,
                  value: user.uuid!,
                }))}
                validate={required}
              />
            )}
            <div className="mb-md mt-md">
              <InputField
                label="Nom du client"
                placeholder="Exemple : Jean Dupont ou SARL Dupuis"
                id="client-name"
                data-testid="client-name"
                name="customerName"
                validate={required}
              />
            </div>
            <div className="mb-md">
              <InputField
                label="Adresse e-mail du client"
                id="client-email"
                placeholder="Exemple : jean.dupont@mail.com"
                name="customerEmail"
                validate={validateEmail}
              />
            </div>
            <div className="mb-md">
              <InputField
                id="client-phone-number"
                label="Numéro de téléphone du client"
                placeholder="Exemple : 06 12 34 56 78"
                name="customerPhoneNumber"
              />
            </div>
            <div className="mb-md">
              <InputField
                autoFocus
                label="Titre du chantier"
                placeholder="Exemple : Rénovation cuisine"
                id="deal-title"
                data-testid="deal-title"
                name="title"
                validate={required}
              />
            </div>
            <AddressField
              label="Adresse du chantier"
              formattedAddressFieldName="chantierFormattedAddress"
              disabledFieldName="disabledChantierGoogleMaps"
              googleMapsFieldName="chantierGoogleMapsAddress"
              addressComplementFieldName="chantierAddressComplement"
              legacyFieldName="chantierAddress"
              isRequired
            />
          </div>
        </ModalContent>
      )}
    </Form>
  );
};
